import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import OrderedList from "~/components/List/Ordered";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={6}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 6</H2>

          <p>Now that you have read about clinical trials and reviewed aspects of good experimental design, you will look at two real clinical trials.</p>
          <p>Your teacher will assign you to a team of 2-3 students and give you a handout to use.  As a team, you will read about one of the two clinical trials.</p>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_2step_6.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <OrderedList>
            <li>Click on the link below to read about the clinical trial that you have been assigned.</li>
            <li>Your handout lists questions to help you evaluate the quality of the clinical trial. Fill in answers to these questions for the study to which you were assigned. It is OK to leave a box empty if you cannot find an answer in the reading.</li>
            <li>After you read about your assigned clinical trial and answered the questions, you will share what you learned with a team that read about the other clinical trial.</li>
          </OrderedList>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/high-school/lesson-2/step-6a"
              icon="external"
              title="Green coffee bean extract study"
            >
              Green coffee bean extract study
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/high-school/lesson-2/step-6b"
              icon="external"
              title="Diabetes Prevention Program (DPP) study"
            >
              Diabetes Prevention Program (DPP) study
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
